/* eslint-disable no-undef,no-console */
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import { http } from '../../http';
import { getRewardPoints, getListOfThings } from '../index';
import * as actionTypes from '../actionTypes';
import { getWebinarVideoByCategory, getWebinarCategories, allWebinarCategories, fetchAdventureScoreAPI, DeleteQuizQuestionAPI, getQuizAPI, AddQuizAPI, GetVideosCategoriesAPI, GetSelectedVideosCategoriesAPI, GetVideosLibraryAPI, FeaturedJourneyAPI, GetAdventureCategories, GetWellnessJourneysByCategory,
  GetRecommendedVideos, UpdateUserGlobalPoints, UpdateJourneyStatus, EditWellnessJourney, AddWellnessJourney, submitmultipleAnswerAPI, EditQuizAPI, GetRecipeDetailsById, GetFeaturedRecipes, AllRecipeCategories, GetRecipeCategories, CreateRecipe, EditRecipe, GetRecipesByCategory, GetRecommendedRecipes,
  AddVideoByCategory, GetFitnessVideoById, EditVideoByCategory, GetSelectedAdventureCategories, AddCategory, GetThemes, GetFavoriteRecipes, GetFavoriteArticles, GetFavoriteVideos, LikeUnlikeEducationPost, GetUserQuizScore,GetWebinarVideos, GetFeaturedVideos, FeaturedRecommendedAPI, EventPointDetails, GetWellnessJourneysById} from '../../constants/apiConstants';

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const getAdventureCategories = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetAdventureCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_ADVENTURE_CATEGORIES, adventureCategoriesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_ADVENTURE_CATEGORIES, adventureCategories: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getThemes = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetThemes, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_ADVENTURE_CATEGORIES, adventureCategoriesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_THEMES, themes: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getSelectedAdventureCategories = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetSelectedAdventureCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_ADVENTURE_CATEGORIES, adventureCategoriesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_SELECTED_ADVENTURE_CATEGORIES, selectedAdventureCategories: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getFeaturedJourney = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(FeaturedRecommendedAPI, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.SET_FEATURED_JOURNEY, journeys: res.journeys})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getAdventuresByCategory = (categoryName) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellnessJourneysByCategory}/${categoryName}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_CATEGORY_ADVENTURES, adventuresError: res.message});
      } else {
        dispatch({type: actionTypes.GET_CATEGORY_ADVENTURES, adventures: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getAdventureArticle = (id/*, category*/) => (dispatch, getState) => {
  if(!getState().education.adventures || (getState().education.adventures && isEmpty(getState().education.adventures.filter((adventure) => adventure.id === id)))) {
    // dispatch(getAdventuresByCategory(category));
    setTimeout(() => { dispatch({type: actionTypes.GET_ADVENTURE_ARTICLE, id}); }, 5000);
  } else { dispatch({type: actionTypes.GET_ADVENTURE_ARTICLE, id}); }
};

const getVideosCategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetVideosCategoriesAPI, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_VIDEOS_LIBRARY, data: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getSelectedVideosCategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetSelectedVideosCategoriesAPI, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_SELECTED_VIDEOS_LIBRARY, selectedvideoLibraries: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getVideosLibrary = (categoryName) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetVideosLibraryAPI}/${categoryName}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_VIDEOS, data: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getVideoLibraryArticle = (id) => (dispatch, getState) => {
  if(!getState().education.videos || (getState().education.videos && isEmpty(getState().education.videos.filter((video) => video.id === id)))) {
    const category = window.location.pathname.split('/');
    dispatch(getVideosLibrary(category[category.length - 3].replace('-', ' ')));
    setTimeout(() => { dispatch({type: actionTypes.GET_VIDEO_LIBRARY_ARTICLE, id}); }, 3000);
  } else { dispatch({type: actionTypes.GET_VIDEO_LIBRARY_ARTICLE, id}); }
};

const getRecommendedVideos = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetRecommendedVideos, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_RECOMMENDED_VIDEOS, featuredVideosError: res.message});
      } else {
        dispatch({type: actionTypes.GET_RECOMMENDED_VIDEOS, featuredVideos: res.videos});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getFeaturedVideos = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetFeaturedVideos, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_FEATURED_VIDEOS, featureVideosError: res.message});
      } else {
        dispatch({type: actionTypes.GET_FEATURED_VIDEOS, featureVideos: res.videos});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getWebinarLibrary = (categoryName) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${getWebinarVideoByCategory}/${categoryName}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_WEBINAR_VIDEOS, data: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const updateUserGlobalPoints = (actionId, activityType, callBackForUpdaingApiDetails) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const data = {
    "action_id": actionId,
    "activity_type": activityType
  };

  return async (dispatch) => {
    try {
      const res = await http.post(UpdateUserGlobalPoints, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_UPDATE_GLOBAL_POINTS, pointsSuccessError: res.message});
      } else {
        dispatch(getRewardPoints());
        dispatch(fetchAdventureScoreBoard());
        dispatch({type: actionTypes.UPDATE_GLOBAL_POINTS, pointsSuccess: res[0]});
        callBackForUpdaingApiDetails()
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const updateLockStatus = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const data = {
    "journey_id": id
  };

  return async (dispatch) => {
    try {
      const res = await http.put(UpdateJourneyStatus, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_UPDATE_LOCK_STATUS, statusError: res.message});
      } else {
        dispatch({type: actionTypes.UPDATE_LOCK_STATUS, id});
        dispatch({type:actionTypes.UPDATE_WELLNESS_LOCK_STATUS, id});
        dispatch(getListOfThings());
        dispatch(getFeaturedJourney());
        dispatch(fetchAdventureScoreBoard());
      }
    } catch(error) {
      // toast.error(error.message);
      // dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const editWellnessJourney = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.put(EditWellnessJourney, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(getFeaturedJourney());
        window.location.replace('/education/adventure')
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.EDIT_WELLNESS_JOURNEY, journeyEditSuccess: res[0]});
        dispatch(getFeaturedJourney());
        window.location.replace('/education/adventure')
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const editFitnessVideo = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.put(EditVideoByCategory, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        window.location.reload();
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.EDIT_FITNESS_VIDEO, videoEditSuccess: res[0]});
        dispatch(getRecommendedVideos());
        window.location.replace('/education/videos-library')
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const addWellnessJourney = (data, history, isAdminRoute) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.post(AddWellnessJourney, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.ADD_WELLNESS_JOURNEY, addJourneySuccess: res[0]});
        if(isAdminRoute){
          history.push('/company/wellness');
        }else{
          history.push('/education/adventure');
        }
        // dispatch(getFeatukcredJourney());
        dispatch(fetchAdventureScoreBoard());
        dispatch(getAdventureCategories());
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const createJourneyCategory = (data, history, isAdminRoute) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.post(AddCategory, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success('Category Created Successfully');
        dispatch({type: actionTypes.ADD_CATEGORY, addJourneySuccess: res[0]});
        history.push(isAdminRoute?'/company/wellness':data.category_type=="ARTICLE"? '/education/adventure':data.category_type=="FITNESS"?'/education/videos-library':'/education/recipes');
        dispatch(getAdventureCategories());
        dispatch(getFeaturedJourney());
        dispatch(fetchAdventureScoreBoard());
        dispatch(getSelectedAdventureCategories());
        dispatch(getSelectedVideosCategory());
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const AddQuiz = (data, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.post(AddQuizAPI, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(fetchAdventureScoreBoard());
        dispatch(FetchQuizzes(data['journey_id'], 1));
        cb();
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const EditQuiz = (data, journeyId, cb) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.put(EditQuizAPI, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
      }
      dispatch(fetchAdventureScoreBoard());
      dispatch(FetchQuizzes(journeyId), 0);
      cb();
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}
const FetchQuizzes = (id, value) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  let path = id;
  if(value) {
    path += '/'+value;
  }
  return async (dispatch) => {
    dispatch({type: actionTypes.CLEAR_THE_QUIZ})
    try {
      const res = await http.get(`${getQuizAPI}/${path}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        if(res[0]) {
          dispatch({type: actionTypes.FETCH_QUIZZES, quizQuestions: res[0] });
        }
        else if(res.quiz) {
          dispatch({ type: actionTypes.FETCH_SCORE, quizQuestions: res.quiz, quizScore: res.quiz_score})
        }
        
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const SubmitQuiz = (data, categoryName, push) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.post(submitmultipleAnswerAPI, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.QUIZ_SUBMIT_FAIL});
        toast.error(res.message);
        dispatch(getUserQuizScore(data.journey_id));
        // dispatch(getAdventuresByCategory(categoryName));
        push(0)
        // push(`/education/adventure/${categoryName}/article/${data['journey_id']}`);
      } else {
        dispatch({type: actionTypes.QUIZ_SUBMIT_SUCCESS});
        toast.success(res[0]);
        dispatch(getRewardPoints());
        dispatch(getFeaturedJourney());
        dispatch(fetchAdventureScoreBoard());
        dispatch(getUserQuizScore(data.journey_id));
        // dispatch(getAdventuresByCategory(categoryName));
        push(1);
        // push(`/education/adventure/${categoryName}`);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch({type: actionTypes.QUIZ_SUBMIT_FAIL});
      dispatch(networkError(error.message));
    }
  }
}

const DeleteQuizQuestion = (id, articleId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteQuizQuestionAPI}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(FetchQuizzes(articleId, 1));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}

const fetchAdventureScoreBoard = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(fetchAdventureScoreAPI, {headers: { AUTHTOKEN: AuthToken }});
      if(res[0]) {
        toast.error(res[0]);
      }
      else {
        dispatch({ type: actionTypes.ADVENTURE_SCORE_BOARD, res: res })
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getWebinarVideosCategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(getWebinarCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_WEBINAR_VIDEO_CATEGORY, data: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getSelectedWebinarVideosCategory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(allWebinarCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_SELECTED_WEBINAR_VIDEO_CATEGORY, selectedWebinarVideos: res[0]})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getWebinarLibraryArticle = (id) => (dispatch, getState) => {
  
  if(!getState().education.webinarVideos || (getState().education.webinarVideos && isEmpty(getState().education.webinarVideos.filter((video) => video.id === id)))) {
    const category = window.location.pathname.split('/');
    dispatch(getWebinarLibrary(category[category.length - 3].replace('-', ' ')));
    setTimeout(() => { dispatch({type: actionTypes.GET_WEBINAR_LIBRARY_ARTICLE, id}); }, 3000);
  } else { dispatch({type: actionTypes.GET_WEBINAR_LIBRARY_ARTICLE, id});}
};

const getRecipeDetailsById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECIPE_LOADING_TRUE});
    try {
      const res = await http.get(`${GetRecipeDetailsById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_RECIPES_DETAILS_BY_ID, recipeDetails: res})
        dispatch({type: actionTypes.RECIPE_LOADING_FALSE});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getFeaturedRecipes = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetFeaturedRecipes, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_FEATURED_RECIPES_DETAILS, featuredRecipes: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecipeCategories = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetRecipeCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        // dispatch(getSelectedRecipeCategories())
        dispatch({ type: actionTypes.GET_RECIPES_CATEGORIES, recipeCategories: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getSelectedRecipeCategories = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(AllRecipeCategories, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SELECTED_RECIPES_CATEGORIES, selectedRecipeCategories: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const addRecipe = (data, history, isAdminRoute) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECIPE_LOADING_TRUE});
    try {
      const res = await http.post(CreateRecipe, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
        }
        dispatch(getRecommendedRecipeDetails());
        dispatch(getSelectedRecipeCategories());
        dispatch(getRecipeCategories());
        dispatch({type: actionTypes.RECIPE_LOADING_FALSE});
        if(isAdminRoute){
          history.push('/company/wellness');
        }else{
          history.push('/education/recipes');
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const editRecipe = (data, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECIPE_LOADING_TRUE});
    try {
      const res = await http.put(EditRecipe,  data,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(getRecommendedRecipeDetails());
        dispatch({type: actionTypes.RECIPE_LOADING_FALSE});
        history.push(`/education/recipes`);
      } else {
        toast.success(res[0]);
        dispatch(getRecommendedRecipeDetails())
        dispatch(getRecipeCategories());
        dispatch(getSelectedRecipeCategories());
        dispatch({type: actionTypes.RECIPE_LOADING_FALSE});
        history.push(`/education/recipes/${data['recipe_id']}`);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecipeDetailsByCategory = (categoryName) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.RECIPE_LOADING_TRUE});
    try {
      const res = await http.get(`${GetRecipesByCategory}/${categoryName}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_RECIPES_CATEGORY_DETAILS, recipeCategoryDetails: res})
        dispatch({type: actionTypes.RECIPE_LOADING_FALSE});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getRecommendedRecipeDetails = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetRecommendedRecipes, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_RECOMMENDED_RECIPES_DETAILS, recommendedRecipes: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const addFitnessVideo = (data, history, isAdminRoute) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.EDUCATION_LOADING});
    try {
      const res = await http.post(AddVideoByCategory, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        window.location.reload();
      } else {
        toast.success(res[0]);
        dispatch({type: actionTypes.ADD_FITNESS_VIDEO, addVideoSuccess: res[0]});
        dispatch(getRecommendedVideos());
        dispatch(fetchAdventureScoreBoard());
        dispatch(getSelectedVideosCategory());
        dispatch(getVideosCategory());
        if(isAdminRoute){
          history.push('/company/wellness');
        }else{
          history.push('/education/videos-library');
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getFitnessVideoById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetFitnessVideoById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_FITNESS_VIDEO_BY_ID, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getFavoriteVideos = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetFavoriteVideos}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_FAVORITE_VIDEOS, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getFavoriteRecipes = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetFavoriteRecipes}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_FAVORITE_RECIPES, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getFavoriteArticles = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetFavoriteArticles}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_FAVORITE_ARTICLES, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const likeUnlikeEducationPost = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(LikeUnlikeEducationPost, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        if(data.type === 'article'){
          dispatch(getFavoriteArticles(10));
          dispatch(getFeaturedJourney());
          dispatch(getRecommendedArticle())
        } else if (data.type === 'fitness'){
          dispatch(getFavoriteVideos(10));
          dispatch(getRecommendedVideos());
          dispatch(getFitnessVideoById(data.type_id));
        }else {
          dispatch(getFavoriteRecipes(10));
          dispatch(getFeaturedRecipes());
          dispatch(getRecommendedRecipeDetails());
          dispatch(getRecipeDetailsById(data.type_id))
        }
        toast.success(res[0] || '');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getUserQuizScore = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserQuizScore}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_USER_QUIZ_SCORE, data: res})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getWebinarVideos = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetWebinarVideos, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_NEW_WEBINAR_VIDEOS, featuredWebinarVideosError: res.message});
      } else {
        dispatch({type: actionTypes.GET_NEW_WEBINAR_VIDEOS, featuredWebinarVideos: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getRecommendedArticle = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(FeaturedJourneyAPI, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.SET_RECOMMENDED_ARTICLE, journeys: res.journeys})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getEventsPoints = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(EventPointDetails, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({ type: actionTypes.GET_EVENTS_DETAILS_POINTS, eventsDetailsPoints: res})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const fetchsWellnessJourneysById = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellnessJourneysById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        dispatch({type: actionTypes.GET_WELLNESS_JOURNEYS_ID, journeyDetails: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
};

export { getWebinarLibraryArticle, getWebinarLibrary, getWebinarVideosCategory, getSelectedWebinarVideosCategory, fetchAdventureScoreBoard, DeleteQuizQuestion, SubmitQuiz, FetchQuizzes, AddQuiz, getVideosCategory,getSelectedVideosCategory, getVideosLibrary, getFeaturedJourney, getAdventureCategories, getAdventuresByCategory, getAdventureArticle,
  getVideoLibraryArticle, getRecommendedVideos, updateUserGlobalPoints, updateLockStatus, editWellnessJourney, addWellnessJourney, EditQuiz, getRecipeDetailsById, getFeaturedRecipes, getRecipeCategories, getSelectedRecipeCategories, addRecipe, editRecipe, getRecipeDetailsByCategory, getRecommendedRecipeDetails, addFitnessVideo, getFitnessVideoById,
  editFitnessVideo, getSelectedAdventureCategories, createJourneyCategory, getThemes, getFavoriteArticles, getFavoriteVideos, getFavoriteRecipes, likeUnlikeEducationPost, getUserQuizScore,getWebinarVideos, getFeaturedVideos, getRecommendedArticle, getEventsPoints, fetchsWellnessJourneysById};

